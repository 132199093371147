import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"

import gql from "graphql-tag"

import { Bleach } from "@/main.js"

let API, store

import Account from "./BleachAPI/Account"
import Cart from "./BleachAPI/Cart"
import Consultations from "./BleachAPI/Consultations"
import Products from "./BleachAPI/Products"
import Subscriptions from "./BleachAPI/Subscriptions"

export default {
  ...Account.methods,
  ...Cart.methods,
  ...Consultations.methods,
  ...Products.methods,
  ...Subscriptions.methods,

  initAPI: async storeReference => {
    store = storeReference

    // Retrieve the schema from the API to establish any union types that need matching
    let _cache = await new ApolloClient({
      link: new HttpLink({
        uri: `${process.env.VUE_APP_BASE_API_URL}/graphql`
      }),
      cache: new InMemoryCache({})
    })
      .query({
        query: gql`
          query BleachSite_GetSchema {
            __schema {
              types {
                kind
                name
                possibleTypes {
                  name
                }
              }
            }
          }
        `
      })
      .then(result => {
        // TODO:
        const fragmentMatcher = new IntrospectionFragmentMatcher({
          introspectionQueryResultData: result.data
        })
        return new InMemoryCache({ fragmentMatcher })
      })
      .catch(err => Bleach.debug.warn(err))

    Bleach.debug.log(_cache)

    const defaultClient = new ApolloClient({
      link: new HttpLink({
        uri: `${process.env.VUE_APP_BASE_API_URL}/graphql`
      }),
      cache: _cache
    })

    API = {
      Default: defaultClient,
      Secure: {
        query(payload) {
          return defaultClient.query({
            ...payload,
            context: {
              headers: {
                authorization: `Bearer ${store.state.identity.authJWT}`
              }
            }
          })
        },
        mutate(payload) {
          return defaultClient.mutate({
            ...payload,
            context: {
              headers: {
                authorization: `Bearer ${store.state.identity.authJWT}`
              }
            }
          })
        }
      }
    }

    // API.Default.defaultOptions = API.Secure.defaultOptions = {
    //   query: {
    //     errorPolicy: "all"
    //   }
    // }

    // initialise modules in turn
    ;[Account, Cart, Consultations, Products, Subscriptions].forEach(module => {
      module.init(API, store)
    })
  },

  getBuuidFromEmail: function(email) {
    return API.Default.query({
      query: gql`
        query BleachSite_GetBuuidFromEmail {
          user(email: "${email}") {
            buuid
          }
        }
      `
    })
      .then(res => res)
      .catch(err => console.log(err))
  },

  getGeoLocation: function() {
    return API.Default.query({
      query: gql`
        query BleachSite_GetGeolocation {
          geoLocation {
            locale
            shopName
            timeZone {
              code
            }
          }
        }
      `
    })
      .then(res => res)
      .catch(err => console.log(err))
  },

  checkGiftCardBalance: function(cardCode) {
    return API.Default.query({
      query: gql`
        query BleachSite_GetGiftCardDetails($code: String!) {
          giftCardDetails(code: $code) {
            balance {
              cents
              currency
            }
            currency
            disabledAt
            expiresOn
          }
        }
      `,
      variables: {
        code: cardCode
      }
    })
      .then(res => res)
      .catch(err => console.log(err))
  },

  updateUserAttributes: function({
    firstName = null,
    lastName = null,
    subscribedToMarketing
  }) {
    const shopName =
      store && store.state && store.state.locale && store.state.locale.shopName
    let subscribedToMarketingString =
      subscribedToMarketing !== undefined
        ? `subscribedToMarketing: ${subscribedToMarketing}`
        : ``

    let firstNameString =
      firstName !== null ? `firstName: "${firstName}", ` : ""
    let lastNameString = lastName !== null ? `lastName: "${lastName}", ` : ""

    return API.Secure.mutate({
      mutation: gql`
        mutation BleachSite_registerUpdateProfile($shopName: String) {
          updateProfile(${firstNameString} ${lastNameString} ${subscribedToMarketingString}) {
            errors
            result
            user {
              buuid
              firstName
              lastName
              bornOn
              email
              orders(limit: 10) {
                bleachOrderId
                createdAt
                financialStatus
                fulfillmentStatus
                id
                lineItems {
                  ecomProduct {
                    ... on Product {
                      displayTitle
                    }
                    ... on ProductBundle {
                      displayTitle
                    }
                    ... on GiftCard {
                      displayTitle
                    }
                  }
                  id
                  price {
                    cents
                    currency
                  }
                  subscription
                  quantity
                }
                shipping {
                  address {
                    address1
                    address2
                    postCode
                    city
                    state
                    countryCode
                  }
                  name
                  price {
                    cents
                    currency
                  }
                }
                totalPrice {
                  cents
                  currency
                }
                refundedAmount {
                  cents
                  currency
                }
                trackingUrl
              }
              subscribedToMarketing
              subscriptions(shopName: $shopName) {
                id
                ecomProduct {
                  ... on Product {
                    sku
                  }
                  ... on ProductBundle {
                    sku
                  }
                }
                status
                nextChargeScheduledAt
                deliveryAddress {
                  address1
                  address2
                  postCode
                  city
                  countryCode
                }
                orderIntervalFrequency
                orderIntervalUnit
                price {
                  cents
                  currency
                }
              }
            }
          }
        }
      `,
      variables: {
        shopName: shopName
      }
    })
  },

  subscribeToNewsletter: function(email) {
    const shopName =
      store && store.state && store.state.locale && store.state.locale.shopName

    return API.Default.mutate({
      mutation: gql`
        mutation BleachSite_NewsletterSignUp {
          newsletterSignup(email: "${email}", shopName: "${shopName}") {
            result
          }
        }
      `
    })
  },

  getBuuid: async function() {
    let buuid
    await API.Secure.query({
      query: gql`
        query BleachSite_MyBuuid {
          user {
            buuid
          }
        }
      `
    })
      .then(res => {
        buuid = res.data.user.buuid
      })
      .catch(err => {
        console.log(err)
      })
    return buuid
  },

  submitUserHairState: function({ question, response }) {
    return API.Secure.mutate({
      mutation: gql`
      mutation BleachSite_RecordHairAttribute {
        recordHairAttribute(name: "${question}", value: "${response}") {
          result
        }
      },
      `
    })
      .then(res => {
        return res
      })
      .catch(err => {
        console.log(err)
      })
  },

  submitUserHairStateBulk: function(HairStateArray) {
    return API.Secure.mutate({
      mutation: gql`
        mutation BleachSite_RecordHairAttributes(
          $hairAttributes: [HairAttributeInput!]!
        ) {
          recordHairAttributes(hairAttributes: $hairAttributes) {
            result
          }
        }
      `,
      variables: {
        hairAttributes: HairStateArray
      }
    })
      .then(res => {
        return res
      })
      .catch(err => {
        console.log(err)
      })
  },

  getUserProfile: async function(shopName = null) {
    // If no Shopify store is passed, use the existing one
    if (!shopName)
      shopName =
        store &&
        store.state &&
        store.state.locale &&
        store.state.locale.shopName
    return new Promise((resolve, reject) => {
      API.Secure.query({
        query: gql`
          query BleachSite_GetUserProfile($shopName: String) {
            user {
              buuid
              firstName
              lastName
              bornOn
              email
              orders(limit: 10) {
                bleachOrderId
                createdAt
                financialStatus
                fulfillmentStatus
                id
                lineItems {
                  ecomProduct {
                    ... on Product {
                      displayTitle
                    }
                    ... on ProductBundle {
                      displayTitle
                    }
                    ... on GiftCard {
                      displayTitle
                    }
                  }
                  id
                  price {
                    cents
                    currency
                  }
                  subscription
                  quantity
                }
                shipping {
                  address {
                    address1
                    address2
                    postCode
                    city
                    state
                    countryCode
                  }
                  name
                  price {
                    cents
                    currency
                  }
                }
                totalPrice {
                  cents
                  currency
                }
                refundedAmount {
                  cents
                  currency
                }
                trackingUrl
              }
              subscribedToMarketing
              subscriptions(shopName: $shopName) {
                id
                ecomProduct {
                  ... on Product {
                    sku
                  }
                  ... on ProductBundle {
                    sku
                  }
                }
                status
                nextChargeScheduledAt
                deliveryAddress {
                  address1
                  address2
                  postCode
                  city
                  countryCode
                }
                orderIntervalFrequency
                orderIntervalUnit
                price {
                  cents
                  currency
                }
              }
            }
          }
        `,
        variables: {
          shopName: shopName
        },
        fetchPolicy: "network-only"
      })
        .then(res => {
          resolve(res.data.user)
        })
        .catch(error => {
          console.log(error)
          reject({ code: "GQL_GetUserProfileError", error })
        })
    })
  },

  hairQuizSignup(email) {
    return API.Default.mutate({
      mutation: gql`
      mutation BleachSite_HairQuizSignup {
        hairQuizSignup(email: "${email}") {
          result
        }
      }
      `
    })
  },

  waitlistForProduct(ecomVariantId, email) {
    return API.Default.mutate({
      mutation: gql`
      mutation BleachSite_WaitlistProduct {
        subscribeToWaitlist(email: "${email}", ecomVariantId: "${ecomVariantId}") {
          result
          errors
        }
      }`
    })
  }
}
